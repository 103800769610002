// Create a context
import { createContext, useContext, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const AppContext = createContext();

// Create a provider component
export const AppContextProvider = ({ children }) => {
	const [currentStep, setCurrentStep] = useState("login");

	return (
		<AppContext.Provider value={{ currentStep, setCurrentStep }}>
			<GoogleReCaptchaProvider
				reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
				language="cs"
			>
				{children}
			</GoogleReCaptchaProvider>
		</AppContext.Provider>
	);
};

// Custom hook to consume the context
export const useAppContext = () => useContext(AppContext);
