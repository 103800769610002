import "./bootstrap";
import "../scss/app.scss";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { AppContextProvider } from "@/Context/AppContext.jsx";

createInertiaApp({
	title: (title) => `${title}`,
	resolve: (name) =>
		resolvePageComponent(
			`./Pages/${name}.jsx`,
			import.meta.glob("./Pages/**/*.jsx"),
		),
	setup({ el, App, props }) {
		const root = createRoot(el);

		root.render(
			<AppContextProvider>
				<App {...props} />
			</AppContextProvider>,
		);
	},
	progress: {
		color: "#4B5563",
	},
});
